// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useEffect } from 'react';
import { auth } from 'utils/firebase';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import getMenuItems from 'menu-items';
import { Roles } from 'enum/roles';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [user] = useAuthState(auth);
    const [menuItems, setMenuItems] = useState(getMenuItems());

    useEffect(() => {
        if (!user) return;
        if (user.email === 'bryansmithcollazos@gmail.com') setMenuItems(getMenuItems(Roles.DEVELOPER));
    }, [user]);

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
