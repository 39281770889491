import stats from './stats';
import utilities from './utilities';
import tests from './test';
import { Roles } from 'enum/roles';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = [stats, tests];

const menuItemsDev = [utilities, ...menuItems];

const getMenuItems = (role) => {
    if (role === Roles.DEVELOPER) return menuItemsDev;
    else return menuItems;
};

export default getMenuItems;
