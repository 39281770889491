// assets
import { IconHelp, IconListCheck } from '@tabler/icons';

// constant
const icons = { IconListCheck, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const tests = {
    id: 'tests',
    title: 'Tests',
    type: 'group',
    children: [
        {
            id: 'test',
            title: 'Empezar nuevo test',
            type: 'item',
            url: '/test',
            icon: icons.IconListCheck,
            breadcrumbs: false
        }
    ]
};

export default tests;
