// TODO: God class, repeated code...

import { Roles } from 'enum/roles';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from 'firebase/firestore';
const firebaseConfig = {
    apiKey: 'AIzaSyCE0zbEFT4OCLc_Z9_2f2iQh8aGKmcYPL8',
    authDomain: 'oposiciones-metro.firebaseapp.com',
    projectId: 'oposiciones-metro',
    storageBucket: 'oposiciones-metro.appspot.com',
    messagingSenderId: '874003799630',
    appId: '1:874003799630:web:e884432397e8efbbf5ed1f',
    measurementId: 'G-RBXVL925KT'
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    const res = await signInWithPopup(auth, googleProvider);
    const gUser = res.user;

    const userReference = doc(db, 'users', auth.currentUser.uid);
    try {
        const user = await getDoc(userReference);
        if (!user.exists()) {
            await setDoc(userReference, {
                name: gUser.displayName,
                authProvider: 'google',
                email: gUser.email,
                photoURL: gUser.photoURL,
                role: Roles.USER
            });
        }
    } catch (error) {
        console.error(error);
    }
};

const logout = () => {
    signOut(auth);
};

const getTest = async (id) => {
    const idReference = doc(db, 'questions', `${id}`);
    try {
        const question = await getDoc(idReference);
        return question.data();
    } catch (error) {
        console.error(error);
    }
};

const getTestResults = async () => {
    try {
        const testResults = {};

        const resultsRef = collection(db, 'users', auth.currentUser.uid, 'test_results');
        const resultsDocs = (await getDocs(resultsRef)).docs;

        for (const resultDoc of resultsDocs) {
            const attemptsReference = collection(db, 'users', auth.currentUser.uid, 'test_results', resultDoc.id, 'attempts');
            const attemptsDocs = await getDocs(attemptsReference);

            const reduced = attemptsDocs.docs.reduce((accumulator, currentValue) => {
                accumulator[currentValue.id] = currentValue.data();
                return accumulator;
            }, {});

            const resultDocData = resultDoc.data();
            testResults[resultDoc.id] = { attempts: [], asserts: resultDocData.asserts, fails: resultDocData.fails };
            testResults[resultDoc.id].attempts = reduced;
        }
        return testResults;
    } catch (error) {
        console.error(error);
    }
};

const reportTestResult = async (id, result) => {
    try {
        const date = new Date().toISOString().slice(0, 10);
        const idReferenceDateDoc = doc(db, 'users', auth.currentUser.uid, 'test_results', date);
        const dateDoc = await getDoc(idReferenceDateDoc);

        const dayAssertsFails = {
            asserts: 0,
            fails: 0
        };

        if (dateDoc.exists()) {
            const data = dateDoc.data();
            dayAssertsFails.asserts = data.asserts;
            dayAssertsFails.fails = data.fails;
        }

        if (result) {
            dayAssertsFails.asserts = dayAssertsFails.asserts + 1;
            dayAssertsFails.fails = dayAssertsFails.fails;
        } else {
            dayAssertsFails.asserts = dayAssertsFails.asserts;
            dayAssertsFails.fails = dayAssertsFails.fails + 1;
        }

        await setDoc(idReferenceDateDoc, dayAssertsFails);

        const idReference = doc(db, 'users', auth.currentUser.uid, 'test_results', date, 'attempts', `${id}`);
        const createdDoc = await getDoc(idReference);
        const assertsFails = {
            asserts: 0,
            fails: 0
        };

        if (createdDoc.exists()) {
            const data = createdDoc.data();
            assertsFails.asserts = data.asserts;
            assertsFails.fails = data.fails;
        }

        if (result) {
            assertsFails.asserts = assertsFails.asserts + 1;
            assertsFails.fails = assertsFails.fails;
        } else {
            assertsFails.asserts = assertsFails.asserts;
            assertsFails.fails = assertsFails.fails + 1;
        }

        await setDoc(idReference, assertsFails);
    } catch (error) {
        console.error(error);
    }
};

export { auth, db, signInWithGoogle, logout, getTest, reportTestResult, getTestResults };
